.invisible-div {
  height: 4rem;
}

.MuiSelectUnstyled-root {
  background-color: white !important;
  font-weight: 600 !important;
  color: #555555 !important;
}

.top-start {
  background-color: red !important;
}

.sync_header {
  display: flex;
  flex-direction: row;
  background-color: #dadada;
  padding: 1% 1.5% 3%;
}

.sync_header p {
  font-weight: 500;
  font-size: 20px;
  color: #1b1b1b;
}

.sync_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #ffffff;
  padding: 3% 3% 2%;
  color: #1b1b1b;
  font-size: 20px;
}

.contrastsync_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #0a295c;
  padding: 3% 3% 2%;
  color: #ffffff;
  font-size: 20px;
}

@media (max-width: 768px) {
  .sync_div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #ffffff;
    padding: 3% 3% 5%;
    color: #1b1b1b;
    font-size: 20px;
  }

  .sync_header {
    display: flex;
    flex-direction: row;
    background-color: #dadada;
    padding: 2% 3.5% 3%;
  }
}

.contrast-sync_content_div {
  background-color: var(--color-primary-darken-02);
  color: var(--color-secondary-01);
  border-radius: 0.2rem;
  width: 100%;
}

.-sync_content_div {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contrast-sync_content_div .br-button:not(:disabled):hover {
  background-color: #d8d8d8 !important;
  color: #071d41 !important;
  cursor: pointer !important;
  border-color: #071d41 !important;
}

.contrast-sync_content_div .br-button:hover {
  background-color: #1351b4 !important;
  color: #ffffff !important;
}

.contrast {
  background-color: var(--color-primary-darken-02);
  color: var(--color-secondary-01);
}

.contrast button:hover {
  background-color: var(--color-primary-darken-01);
}

.contrast input {
  background-color: #0a295c;
}

.options-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.user-category {
  font-weight: bolder;
}

.user-container {
  width: 100%;
}

.user-container .MuiButtonBase-root .MuiSvgIcon-root {
  color: #1351b4 !important;
  font-size: 1.4rem !important;
}

.MuiTablePagination-selectIcon {
  font-size: 1.4rem !important;
}

.MuiTablePagination-input {
  font-size: 0.75rem !important;
}

.MuiTableBody-root {
  font-weight: 300 !important;
}

thead.MuiTableHead-root > tr > th {
  z-index: 0;
}

.container-management {
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.container__sections__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
}

.container__sections__column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex: 1;
  box-shadow: 1px 5px 10px 4px rgba(0, 0, 0, 0.2) !important;
}

.container__search {
  width: 80%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.groups_fields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}

.collapse {
  opacity: 1;
  max-height: 0;
  transition: max-height 0.3s ease-out;
  overflow: scroll;
}

.show {
  opacity: 1;
  max-height: 1000px;

  transition: max-height 0.3s ease-in;
}

.show::-webkit-scrollbar {
  display: none;
}
.collapse::-webkit-scrollbar {
  display: none;
}

/* Style the button that is used to open and close the collapsible content */
.indicator_collapsible_button_field {
  background: none;
  cursor: pointer;
  padding: 2%;
  margin: 0;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.contrastindicator_collapsible_button_field {
  background: none;
  cursor: pointer;
  padding: 2%;
  margin: 0;
  width: 100%;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
}

.indicator_collapsible_button_field:hover {
  background: #dbe8fb;
}
