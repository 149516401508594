.personalization-save-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0% 0 3%;
  margin-left: -20px;
  justify-content: space-between;
}

.personalization-add-content {
  display: flex;
  justify-content: center;
  background-color: #ffff;
  border-radius: 0 0 3px 3px;
}

.personalization-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #ffff;
}

.personalization-contentcontrast {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #0a295c;
}

.container-management
  .contrast
  .personalization-save-content
  .br-button:not(:disabled):hover {
  background-color: #d8d8d8 !important;
  color: #071d41 !important;
  cursor: pointer !important;
  border-color: #071d41 !important;
}

.container-management .contrast .personalization-save-content .br-button:hover {
  background-color: #1351b4 !important;
  color: #ffffff !important;
  cursor: not-allowed !important;
}

.personalization-contentcontrast .br-button:not(:disabled):hover {
  color: #071d41 !important;
}

.personalization-header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffff;
}

.personalization-header-contentcontrast {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #0a295c;
}

.header-card-title {
  display: flex;
  justify-content: center;
  font-size: large;
}

@media (min-width: 1000px) {
  .header-card-title {
    flex: 6.0;
    justify-content: center;
    font-size: large;
  }
}

.header-card-titlecontrast {
  display: flex;
  justify-content: center;
  font-size: large;
  background-color: #0a295c;
}

.header-button {
  display: flex;
}

.col-12 {
  padding: 0;
  margin: 0;
}

.row {
  margin: 0;
}

.publicmain___page {
  width: 100%;
  margin: 0 auto;
  box-shadow: 1px 5px 10px 4px rgba(0, 0, 0, 0.2) !important;
}

.main___page {
  width: 95%;
  margin: 0 auto;
  margin-top: 4%;
  margin-bottom: 4%;
  box-shadow: 1px 5px 10px 4px rgba(0, 0, 0, 0.2) !important;
}

.container-fluid {
  margin: auto;
  width: 100%;
}

.invisible_header_div {
  flex: 0.07;
}

.personalization_header_background {
  background-color: #dadada;
}

.personalization_background {
  background-color: #ffff;
  border-radius: 0 0 3px 3px;
}

.personalization_backgroundcontrast {
  background-color: #0a295c;
  border-radius: 0 0 3px 3px;
}

.personalization-add-contentcontrast {
  display: flex;
  justify-content: center;
  background-color: #0a295c;
  border-radius: 0 0 3px 3px;
}

.personalization-card-outer {
  border: 2px solid #98b3df;
  border-radius: 5px;
  border-style: dashed;
  
}

.contrastpersonalization-card-outer {
  border: 2px solid #1351b4;
  border-radius: 5px;
  border-style: dashed;
}

.personalization-card-outer:hover {
  border: 2px solid var(--color-primary-default);
  border-radius: 5px;
  border-style: dashed;
}

.contrastpersonalization-card-outer:hover {
  border: 2px solid #98b3df;
  border-radius: 5px;
  border-style: dashed;
}

.row__inner {
  flex: 0.5;
}

.row__invisible__div {
  flex: 1;
}

@media only screen and (max-width: 685px) {
  .personalization-content {
    flex-direction: column;
    align-items: center;
  }

  .col-12 {
    padding: 0;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .personalization-save-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0% 0 3%;
    justify-content: space-between;
    margin-left: 0px;
  }
}

@media (max-width: 825px) and (min-width: 788px){
  .personalization-card-outer {
    border: 0px solid #98b3df;
    border-radius: 5px;
    border-style: dashed;
  }
  
  .contrastpersonalization-card-outer {
    border: 0px solid #1351b4;
    border-radius: 5px;
    border-style: dashed;
  }
}


