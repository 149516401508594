.dropbtn_ {
    border: 1px solid #ffffff;
    border-radius: 100em;
    text-align: center;
    height: 2.0rem;
    width: 2.0rem;
    outline: 0;
    position: relative;
    top: 0px;
    left: 4px;
    cursor: pointer;
    color: var(--color-primary-default);
    background-color: #ffffff;
}

.dropbtn_contrast {
    border: 1px solid #1e3860;    ;
    border-radius: 100em;
    text-align: center;
    height: 2.0rem;
    width: 2.0rem;
    outline: 0;
    position: relative;
    top: 0px;
    left: 4px;
    cursor: pointer;
    color: #ffffff;
    background-color: #1e3860;
}


 fa-sort-down{
    color: #333333;
    font-size: 190px !important;
    background-color: transparent
}

.selectDropbtn_ { 
    display: flex;
    flex-direction: row-reverse !important;
    justify-content:center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 200px;
    outline: 0;
    position: relative;
    top: -4px !important;
    padding-top: 8px;
    left: 4px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color:transparent;
}



.MuiList-padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.menu_item_div:hover {
    background-color: #ddd !important;
    color: #1e3860 !important;
}

.menu_item_div {
    font-size: 18px !important;
    padding-top: 20px !important;
}

.MuiSelect-select:focus {
    background-color: transparent !important;
}


.card__filter__button{
    padding-left: 10px !important;
}

.card__position{
    margin-top: -10px !important;
}

@media (max-width: 570px) and (min-width:438px) {
    
    .card__filter__button{
      padding-left: 10px !important;
      margin-top: -10px !important;
    }
}
/* Dropdown button on hover & focus */
.dropbtn_:hover,
.dropbtn_:focus {
    background-color: var(--color-primary-pastel-02);
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown_content_ {
    display: none;
    position: absolute;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    align-items: center;
}



.selectDropbtn_:hover,
.selectDropbtn_:focus {
    background-color: #c4c4c4 !important;
    color: #1e3860 !important;
}

/* The container <div> - needed to position the dropdown content */
.selectDropdown {
    position: relative;
    display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.selectDropdown_content_ {
    display: none;
    position: absolute;
    min-width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    align-items: center;
}

@media (max-width:500px) {
    .dropdown_content_ {
        display: none;
        position: relative;
        min-width: 40px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 2;
        align-items: center;
    }

    .selectDropdown_content_ {
        display: none;
        position: relative;
        min-width: 40px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 2;
        align-items: center;
    }
    

    
}



.dropdown_card {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.dropdown_options_ {
    display: flex;
    flex-direction: row !important;
    cursor: pointer;

}

.dropdown_options_contrast {
    display: flex;
    flex-direction: row !important;
    background-color: var(--color-primary-darken-02);
    cursor: pointer;

}

.dropdown_options_:hover {
    background-color: #ddd;
}

/* Links inside the dropdown */
.dropdown_content_ a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown_content a:hover {
    background-color: #ddd;
}


.dropdown_content_contrast {
    display: none;
    position: absolute;
    background-color: var(--color-primary-darken-02);
    min-width: 40px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.7);
    z-index: 1;
    align-items: center;
}

/* Links inside the dropdown */
.dropdown_content_contrast a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.dropdown_content_contrast a:hover {
    background-color: rgba(241, 241, 241, 0.3);
}

.dropbtn_contrast:hover,
.dropbtn_contrast:focus {
    background-color: rgba(241, 241, 241, 0.3);
}



/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {
    display: block;
}


.showSelect {
    display: block;
}

.selectDropdown_card {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.selectDropdown_options_ {
    display: flex;
    flex-direction: row !important;
    cursor: pointer;
    font-size: 18px !important;

}

.selectDropdown_options_contrast {
    display: flex;
    flex-direction: row !important;
    background-color: var(--color-primary-darken-02);
    cursor: pointer;
    font-size: 18px !important;

}

.selectDropdown_options_:hover {
    background-color: #ddd;
}

/* Links inside the dropdown */
.selectDropdown_content_ a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.selectDropdown_content a:hover {
    background-color: #ddd;
}


.selectDropdown_content_contrast {
    display: none;
    position: absolute;
    background-color: var(--color-primary-darken-02);
    min-width: 40px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.7);
    z-index: 1;
    align-items: center;
}

/* Links inside the dropdown */
.selectDropdown_content_contrast a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color of dropdown links on hover */
.selectDropdown_content_contrast a:hover {
    background-color: rgba(241, 241, 241, 0.3);
}

.selectDropbtn_contrast:hover,
.selectDropbtn_contrast:focus {
    background-color: rgba(241, 241, 241, 0.3);
}



.MuiNativeSelect-select {
    text-align: center !important;
    margin-top: -13% !important;
    padding: 12% 14% 14% 14% !important;
    width: 100px !important;
    color: #333333 !important;
    font-family: 'Rawline' !important;
    font-style: normal !important;
    /* font-weight: 400 !important; */
    font-size:18px !important;
}

label + .MuiInput-formControl {
    margin-top: 14px !important;
    font-size: 18px !important;
    
}

#menu-Gráfico{
    top: 100px !important;
}

.MuiSelect-icon{
    font-size: 1.6rem !important;
    top: calc(50% - 15px) !important;
}

.MuiNativeSelect-icon {
    margin-top: -0.4rem !important;
    align-items: center !important;
    color: #333333 !important;
    /* display: none !important; */

}

.MuiInput-underline::before {
    display: none;
}

.MuiInput-underline::after {
    display: none;
}

.MuiInputLabel-root {
    display: none !important;
}

.card_container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    min-width: 25vh;
    max-width: 180vh;
}

@media (max-width: 860px) {
    .selectDropbtn_ { 
        display: flex;
        flex-direction: row-reverse !important;
        justify-content:center;
        border: 0px solid transparent;
        text-align: center !important;
        height: 48px;
        width: 150px;
        outline: 0;
        position: relative;
        top: -1px !important;
        padding-top: 8px;
        left: 2px;
        cursor: pointer;
        color: #333333;
        font-size: 19px;
        background-color: transparent;
    }
    

}

@media (max-width: 790px) {
    .selectDropbtn_ { 
        display: flex;
        flex-direction: row-reverse !important;
        justify-content:center;
        border: 0px solid transparent;
        text-align: center !important;
        height: 48px;
        width: 200px;
        outline: 0;
        position: relative;
        top: -4px !important;
        padding-top: 8px;
        left: 4px;
        cursor: pointer;
        color: #333333;
        font-size: 19px;
        background-color: transparent;
    }

    
}


@media (max-width: 850px) and (min-width: 790px) {
    .selectDropbtn_ { 
        display: flex;
        flex-direction: row-reverse !important;
        justify-content:center;
        border: 0px solid transparent;
        text-align: center !important;
        height: 48px;
        width: 150px;
        outline: 0;
        position: relative;
        top: -1px !important;
        padding-top: 8px;
        left: 2px;
        cursor: pointer;
        color: #333333;
        font-size: 19px;
        background-color: transparent;
    }
    

    
}

@media (max-width: 500px) {
    .selectDropbtn_ { 
        display: flex;
        flex-direction: row-reverse !important;
        justify-content:center;
        border: 0px solid transparent;
        text-align: center !important;
        height: 48px;
        width: 120px;
        outline: 0;
        position: relative;
        top: -4px !important;
        padding-top: 8px;
        left: 4px;
        cursor: pointer;
        color: #333333;
        font-size: 19px;
        background-color: transparent;
    }
    

    
}

@media (max-width: 430px) {
    .selectDropbtn_ { 
        display: flex;
        flex-direction: row-reverse !important;
        justify-content:center;
        border: 0px solid transparent;
        text-align: center !important;
        height: 48px;
        width: 120px;
        outline: 0;
        position: relative;
        top: 0px !important;
        padding-top: 8px;
        left: 0px;
        cursor: pointer;
        color: #333333;
        font-size: 19px;
        background-color: transparent;
    }
    
   
}

@media (max-width: 340px) {
    .selectDropbtn_ { 
        display: flex;
        flex-direction: row-reverse !important;
        justify-content:center;
        border: 0px solid transparent;
        text-align: center !important;
        height: 48px;
        width: 80px;
        outline: 0;
        position: relative;
        top: 0px !important;
        padding-top: 8px;
        left: 0px;
        cursor: pointer;
        color: #333333;
        font-size: 19px;
        background-color: transparent;
    }
    
 
}
