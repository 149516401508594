.card_container_ {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: var(--text-color);
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  min-width: 25vh;
  max-width: 180vh;
}

.card_container_contrast {
  display: flex;
  flex-direction: column;
  background-color: #1e3860;
  color: var(--color-secondary-01);
  border-radius: 5px;
  box-shadow: 3px 4px 10px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  min-width: 25vh;
  max-width: 180vh;
}

.card_container_>div,
.card_container_contrast>div {
  margin: 0.05rem;
}

/* Card header */
.card__header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 0.5;
  min-height: 166px;

}

.card__header>div {
  margin: 0.5rem;
}

.card__header__title {
  flex: 0.75;
  font-weight: bold;
  text-align: left;
  -webkit-line-clamp: 1;
  overflow: hidden;

}

.card__header__title h4 {
  text-overflow: ellipsis;
  overflow: visible;
}

.card__header__name {
  flex: 0.5;
  font-weight: 500;
  text-align: center;
  -webkit-line-clamp: 1;
  overflow: visible;
}

.header_name_ {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #333333;
  position: relative;
  left: 10px;
}

.header_name_contrast {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #ece6e6;
  position: relative;
  left: 10px;
}

.indicator_n_ {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #333333;
  position: relative;
  left: 10px;
}

.indicator_n_contrast {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #ece6e6;
  position: relative;
  left: 10px;
}

.span_filter {
  font-family: 'Rawline';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #333333;
  position: relative;
  left: 26px;
  top: 15px;
}

.activated_filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  color: #333333;
  position: relative;
  width: 102px;
  height: 35px;
  left: 98px;
  top: -10px;
  background: #c7c750;
  border-radius: 5px;
}

.disable_filter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 16px;
  color: #000000;
  position: relative;
  width: 102px;
  height: 35px;
  left: 98px;
  top: -10px;
  background: #aaaa9d;
  opacity: 0.75;
  border-radius: 5px;
}

.card__header__name h5 {
  text-overflow: ellipsis;
  overflow: visible;
}

.card__header__description {
  flex: 0.25;
  font-weight: 300;
}

@media (min-width: 1000px) {

  .card__header__description {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: 15px;
    font-weight: 300;
  }
}

/* Card filter */

.card__filter {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  padding: 2px 0px;
  gap: 16px;
  position: relative;
  height: 50px;
  width: 100%;
  right: 0.5px;
  background-color: #DCDCDC;
  justify-content: space-evenly;
}

.card__filter__invisible {
  flex: 0.75;
}

.card__filter__button {
  height: 50px;
  flex: 0 0.25;
  display: flex;
  flex-direction: row;
  justify-content: right;
  right: -17px;
  position: relative;
  top: -3px;
  margin-right: 20px;
}

@media (max-width: 900px) and (min-width:790px) {
  .card__filter__button .MuiFormControl-root .MuiSelect-selectMenu {
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }



  .span_filter {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
    position: absolute;
    left: 5px;
    top: 9px;
  }


  .disable_filter {
    left: 40px;
    top: 1px;
  }

  .activated_filter {
    left: 30px;
    top: 5px;
  }
}

@media (max-width: 570px) {
  .card__filter__button {
    margin-right: 0px;
    justify-content: end;
    position: initial;
  }

  .card__filter__button .MuiFormControl-root {
    margin-right: 0px;
    max-width: 100%;


  }

  .card__filter__button .MuiFormControl-root .MuiSelect-selectMenu {
    height: auto;
    overflow: hidden;
    min-height: 1.1876em;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
  }
}



/* Card Content */
.card__content {
  min-height: 33vh;
  max-height: 33vh;
  /* max-width: 70vh; */
  flex: 1;
  text-align: center;
  overflow-x: auto;
  overflow-y: auto;
  padding: 1%;
}

/* Card footer */

.card__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card__footer>div {
  margin: 0.5rem;
}

.chart {
  height: 30vh;
  width: 100%;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 868px) {
  .card__content {
    justify-content: center;
  }

  .card__header {
    justify-content: space-between;
  }

  .card__header__name {
    text-align: start;
    flex: 0.75;
  }

  .card__header__description {
    flex: 0.25;
    text-align: center;
  }
}

@media only screen and (max-width: 435px) {
  .card__filter {
    flex-direction: column;
    align-items: flex-end;
    padding: 2px 2px 2px 20px;
    justify-content: center;

  }

  .span_filter {
    font-family: 'Rawline';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
    position: absolute;
    left: -10px;
    top: 12px;
  }


  .disable_filter {
    left: 30px;
    top: 5px;
  }

  .activated_filter {
    left: 30px;
    top: 5px;
  }
}



@media (max-width: 860px) {
  .selectDropbtnPers_ {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 150px;
    outline: 0;
    position: relative;
    top: -1px !important;
    padding-top: 8px;
    left: 2px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

  .selectDropbtnPers_contrast {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 150px;
    outline: 0;
    position: relative;
    top: 0px;
    left: 4px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: #1e3860;
  }

}

@media (max-width: 790px) {
  .selectDropbtnPers_ {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 200px;
    outline: 0;
    position: relative;
    top: -4px !important;
    padding-top: 8px;
    left: 4px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

  .selectDropbtnPers_contrast {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 200px;
    outline: 0;
    position: relative;
    top: 0px;
    left: 4px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: #1e3860;
  }

}

.selectDropbtnPers_contrast:hover,
.selectDropbtnPers_contrast:focus {
  background-color: rgba(241, 241, 241, 0.3);
}

.selectDropbtnPers_:hover,
.selectDropbtnPers_:focus {
  background-color: #c4c4c4 !important;
  color: #1e3860 !important;
}


.selectDropbtnPers_ {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: center;
  border: 0px solid transparent;
  text-align: center !important;
  height: 48px;
  width: 200px;
  outline: 0;
  position: relative;
  top: 0px !important;
  padding-top: 8px;
  left: 4px;
  cursor: pointer;
  color: #333333;
  font-size: 19px;
  background-color: transparent;
}

.selectDropbtnPers_contrast {
  display: flex;
  flex-direction: row-reverse !important;
  justify-content: center;
  border: 0px solid transparent;
  text-align: center !important;
  height: 48px;
  width: 200px;
  outline: 0;
  position: relative;
  top: 3px;
  left: 4px;
  cursor: pointer;
  color: #333333;
  font-size: 19px;
  background-color: transparent;
}


@media (max-width: 850px) and (min-width: 790px) {
  .selectDropbtnPers_ {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 150px;
    outline: 0;
    position: relative;
    top: 2px !important;
    padding-top: 8px;
    left: 2px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

  .selectDropbtnPers_contrast {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 150px;
    outline: 0;
    position: relative;
    top: 4px;
    left: 2px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

}

@media (max-width: 500px) {
  .selectDropbtnPers_ {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 120px;
    outline: 0;
    position: relative;
    top: 1px !important;
    padding-top: 8px;
    left: 0px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

  .selectDropbtnPers_contrast {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    text-align: center;
    height: 48px;
    width: 120px;
    outline: 0;
    position: relative;
    top: 8px;
    left: 0px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

}

@media (max-width: 430px) {
  .selectDropbtnPers_ {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 100px;
    outline: 0;
    position: relative;
    top: 0px !important;
    padding-top: 8px;
    left: 0px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

  .selectDropbtnPers_contrast {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 100px;
    outline: 0;
    position: relative;
    top: 4px;
    left: 0px;
    cursor: pointer;
    font-size: 19px;
    color: #333333;
    background-color: transparent;
  }

}

@media (max-width: 340px) {
  .selectDropbtnPers_ {
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    border: 0px solid transparent;
    text-align: center !important;
    height: 48px;
    width: 80px;
    outline: 0;
    position: relative;
    top: 0px !important;
    padding-top: 8px;
    left: 0px;
    cursor: pointer;
    color: #333333;
    font-size: 19px;
    background-color: transparent;
  }

  .selectDropbtnPers_contrast {
    border: 0px solid transparent;
    text-align: center !important;
    display: flex;
    flex-direction: row-reverse !important;
    justify-content: center;
    text-align: center;
    height: 48px;
    width: 80px;
    outline: 0;
    position: relative;
    top: 4px;
    left: 0px;
    font-size: 19px;
    cursor: pointer;
    color: #333333;
    background-color: transparent;
  }

}